.parentPresent1 {
    margin-top: 2rem;
    font-size: 3rem;
}
.parentPresent2 {
    margin-top: 5rem;
    font-size: 4.25rem;
}
.parentPresent3 {
    margin-top: 8rem;
    font-size: 4rem;
}
.parentPresent4 {
    margin-top: 12rem;
    font-size: 5rem;
}

.parentPresent1[data-isOpen="true"] {
    margin-top: 0rem;
    font-size: 3.75rem;
    border-width: 0em;
}
.parentPresent2[data-isOpen="true"] {
    margin-top: 0rem;
    font-size: 3.75rem;
    border-width: 0em;
}
.parentPresent3[data-isOpen="true"] {
    margin-top: 0rem;
    font-size: 3.75rem;
    border-width: 0em;
}
.parentPresent4[data-isOpen="true"] {
    margin-top: 0rem;
    font-size: 3.75rem;
    border-width: 0em;
}